import React, { Component } from "react";
import cx from "classnames";
import { Link } from "gatsby";
import "./CommercialNav.css";

class CommercialNav extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      height: 0
    };
  }

  onOpen = () => {
    const { projects } = this.props;
    this.setState({
      open: true,
      height: Math.ceil(projects.length) * 40
    });
  };

  onClose = () => {
    this.setState({
      open: false,
      height: 0
    });
  };

  onToggle = () => {
    const { open } = this.state;
    if (open) {
      this.onClose();
    } else {
      this.onOpen();
    }
  };

  render() {
    const { projects, project } = this.props;
    const { height, open } = this.state;
    return (
      <div className="CommercialNav">
        <div className="CommercialNav__Breadcrumb" onClick={this.onToggle}>
          Project
          <img
            className="CommercialNav__Arrow"
            src="/img/icon-arrow.svg"
            alt="Next"
          />
          <span className="CommercialNav__Current">{project.title}</span>
          <img
            className="CommercialNav__Arrow CommercialNav__Arrow--Down"
            src="/img/icon-arrow.svg"
            alt="Next"
          />
        </div>
        <div
          className={cx("CommercialNav__Menu", {
            "CommercialNav__Menu--Open": open
          })}
          style={{ height }}
        >
          {projects.map(project => (
            <Link
              key={project.fields.slug}
              to={project.fields.slug}
              className="CommercialNav__MenuItem"
              activeClassName="CommercialNav__MenuItem--Active"
            >
              {project.frontmatter.title}
            </Link>
          ))}
        </div>
      </div>
    );
  }
}

export default CommercialNav;
